<template>
  <div class="text-head">
    <div class="text-head-body w1200">
      <img src="@/assets/img/logo.jpg" alt="" class="logo" @click="$router.push('/')" />
      <div class="title">{{title}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextHead",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.text-head {
  background-color: #fff;
  .text-head-body {
    padding: 20px;
    display: flex;
    .logo {
      height: 100px;
      cursor: pointer;
    }
    .title {
      color: #bababa;
      font-size: 28px;
      height: 49px;
      padding-left: 20px;
      padding-top: 5px;
      margin: 22px 0;
      border-left: 2px solid #e7e8e7;
    }
  }
}
</style>
