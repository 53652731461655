<template>
  <div class="tenants">
    <TextHead :title="'商家入驻'" />
    <div class="banner-main">
      <div class="banner-main-earth"></div>
      <div class="banner-main-track"></div>
    </div>
    <el-card>
      <div class="main">
        <div class="left">
          <div class="title">入驻流程</div>
          <div class="body">
            <h3>一、提交入驻资料</h3>
            <p>
              品牌：招募优质企业，多数类目不限定招商<br />
              企业：合法登记的企业用户，并且能够提供入驻要求的所有相关文件，<br />
              不接受个体工商户、非中国大陆企业入
            </p>
            <h3>二、准备资质材料</h3>
            <p>
              请关注选择经营的类目、店铺类型,商品来源准备的资料若为复印件，请加盖开店公司红色公章<br />
              如申请材料缺少，会退回给您重新提交， 建议您事先准备齐全资料，
              一次性通过审核<br />
            </p>
            <h3>三、网上首页点击注册登录，按照提示注册账号注册账号信息</h3>
            <h3>四、填写企业信息</h3>
            <p>
              根据企业经营需要，选择对应的商品类型及类目入驻。各店商品类型对应的资质
            </p>
            <h3>五、填写品牌信息</h3>
            <p>填写经营的商品信息，并按照平台规则进行正确的要求填写</p>
            <h3>六、提交审核</h3>
            <p>点击对应的协议查看并勾选，提交注册等待审核</p>
            <h3>七、资质审核</h3>
            <p>
              资质审核内容（公司资质、商品资质<br />
              资质真实有效<br />
              达到入驻要求<br />
              授权有效，链路完整<br />
              生产、经营范围、产品安全性资质完整、符合国家行政法规许可要求
            </p>
            <h3>八、审核进度查询</h3>
            <p>商家可查询注册进度。注册过程中的重要信息，我们会以邮件形式发送至您在入驻流程注册的邮箱号。</p>
          </div>
        </div>
        <div class="right">
          <div class="title">注册之后您可以</div>
          <div class="body">
              <p>购买商品支付订单</p>
              <p>收藏商品关注店铺</p>
              <p>安全交易诚信无忧</p>
              <p>积分获取优惠购物</p>
              <p>会员等级享受特权</p>
              <p>评价晒单站外分享</p>
          </div>
        </div>
      </div>
    </el-card>
    <MallFooter />
  </div>
</template>

<script>
import TextHead from "@/views/TextHead.vue";
import MallFooter from "@/views/MallFooter.vue";
export default {
  name: "tenants",
  components: {
    TextHead,
    MallFooter,
  },
};
</script>

<style lang="scss" scoped>
@keyframes screenRotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(1turn);
  }
}
.banner-main {
  width: 100%;
  min-width: 1200px;
  height: 540px;
  position: relative;
  background: #1526b4 url("../assets/carousel-img/banner-bg.jpg") center
    no-repeat;
  cursor: pointer;
  z-index: 10;
  .banner-main-earth {
    width: 312px;
    height: 312px;
    position: absolute;
    top: 50px;
    left: 50%;
    margin-left: -156px;
    background: url("../assets/carousel-img/join-main-earth.png") center
      no-repeat;
    background-size: cover;
    animation: screenRotate 20s linear infinite;
    -webkit-animation: screenRotate 20s linear infinite;
    -moz-animation: screenRotate 20s linear infinite;
    -o-animation: screenRotate 20s linear infinite;
  }
  .banner-main-track {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -128px 0 0 -200px;
    width: 400px;
    height: 150px;
    background: url("../assets/carousel-img/join-main-track.png") center
      no-repeat;
    background-size: cover;
  }
}
.el-card {
  width: 800px;
  margin: 20px auto 0;
  .main {
    display: flex;
    justify-content: space-between;
    .left {
      width: 600px;
      .title {
        font-size: 18px;
        color: #f32613;
        padding-bottom: 10px;
        text-align: center;
        border-bottom: 3px solid #f32613;
      }
      .body {
        padding: 30px;
        p {
          padding-left: 30px;
          line-height: 20px;
        }
      }
    }
    .right {
      width: 140px;
      padding-top: 70px;
      padding-left: 60px;
      color: #999;
      .title {
        font-weight: 700;
        color: #000;
        margin-bottom: 30px;
      }
    }
  }
}
</style>
